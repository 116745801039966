.main_container {
  display: flex;
  flex-direction: row;
}

.tableClass {
  border: solid 1px;
  box-shadow: 1px 1px;
  width: 90%;
  padding: 5px 5px 0px 5px;

}

.divClass {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  height: 81vh;
  overflow-y: scroll;
}

.graph_container {
  width: 75%;
  margin-left: 2%;
  padding: 2px;

  .footer {
    display: flex;
    justify-content: space-between;

    input {
      height: 15px;
      width: 15px;

      &:hover {
        cursor: pointer;
      }
    }

    .chk_txt {
      display: flex;
      justify-items: center;

      span {
        line-height: 21px;
        font-weight: bold;
      }
    }

    #actual:checked {
      accent-color: Olive;
    }

    #actualDark:checked {
      accent-color: yellow;
    }

    #weekAgo:checked {
      accent-color: darkviolet;
    }

    #weekAgoDark:checked {
      accent-color: orchid;
    }

    #dayAgo:checked {
      accent-color: deeppink;
    }

    #dayAgoDark:checked {
      accent-color: hotpink;
    }

    #da:checked {
      accent-color: red;
    }

    #daDark:checked {
      accent-color: salmon;
    }

    #awardedLoad:checked {
      accent-color: blue;
    }

    #awardedLoadDark:checked {
      accent-color: Aqua;
    }

    #bundeledLoad:checked {
      accent-color: chocolate;
    }

    #bundeledLoadDark:checked {
      accent-color: orange;
    }

    #do8am:checked {
      accent-color: teal;
    }

    #do8amDark:checked {
      accent-color: springgreen;
    }
  }
}