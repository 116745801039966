@import './color.palette.scss';

html, body { 
  height: 100%;
}

body {
  margin: 0;
  font-family: "open-sans",sans-serif;
}

*{
  font-size: 11px;
}

.MuiButton-root{
  background-color: $green !important;
  font-weight: bold !important;
  border-radius: 0px !important; 
  color: white !important;;
}

input{
  height: 20px;
  width: 100px;
}
