@import '../../color.palette.scss';

.container{
    display: flex;
}

.table-container{
    width: unset !important;
    height: calc(100vh - 200px);
    margin: 10px;
}

.menu-bar{
    height: 40px;
    display: flex;
    align-items: center;
}

.MuiTableCell-root{
    font-size: 11px !important;
    padding: 12px !important;
    font-weight: bold !important;
}

.MuiTableCell-head{
    color: $pewter !important;
}
