:host{
    position: fixed;
}

.container-noauth{
    display: flex;
    height:100vh;
    width:100vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.errMsg{
    font-weight: bold;
    font-size: 14px;
}

#icon-alert{
    height: 84px;
    width: 84px;
    color: red;
}