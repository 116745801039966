$yellow: #FED141;
$black: #000000;
$heather: #F5F5F5;

//secondary colors - texts, backgrounds, graphic elements
$green: #00664F;
$seaglass: #6DC9C9;
$pewter: #53565A;

//others - acccent colors(used for emphasis in small quantities)
$col1: #FBB04C; //oranges
$col2: #F0B323;

$col3: #D3DF44; //yellows

$col4: #658D1B; //greens
$col5: #78BE20;
$col6: #006269;

$col7: #6DC9C9; //blues
$col8: #00A9E0;
$col9: #A5B7BA;

$col10: #722257; //reds