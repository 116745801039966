.spinner-container{
    position: absolute;
    top: 0px;
    left: 0px;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw; 
    z-index: 10;
    backdrop-filter: blur(2px);
}