.main_container {
  display: flex;
  flex-direction: row;
}

.tableClass {
  border: solid 1px;
  box-shadow: 1px 1px;
  width: 95%;
  padding: 5px 5px 0px 5px;

}

.divClass {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  height: 81vh;
  overflow-y: scroll;
  width:320px;
}
.priceInfoGrid{
  display: grid;
  grid-template-columns: 69.5% 29.5%;
  width: 294px;
  height: 181px;
  padding:0px; 
  margin: 0px;   
  grid-gap: 5px;
}
.innerGrid1{
  display: grid;
  grid-template-columns: 48.6% 48.6%;
  grid-template-rows: 48.7% 48.7%;
  height: 181px;
  width:205px;
  grid-gap: 5px;
  padding:0px;
  margin: 0px; 
}
.innerGrid1> div{
  background-color: lightslategray;    
  height: 88px;
  width:100px;
  text-align: center;
  color:white;
  padding:0px;  
  margin: 0px;   
}


.innerGrid2{
  text-align: center;
  background-color: lightslategray;
  height:181px;
  width:83px;
  color:white;    
}

.headStyle{
background-color: rgb(149, 252, 241);
}
.boldText{
font-weight: bold;
height:30px;
padding-top: 7px;  
padding-left: 3px;
}

