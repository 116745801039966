@import '../../color.palette.scss';

header {
    display: flex;
    height: 60px;
    background-color: $heather;
}

.header-lft {
    display: flex;
    width: 38vw;
    align-items: center;
    background-color: $yellow;
}

img{
    padding-left: 20px;
    padding-right: 20px;
    border-right: 2px solid black;
}

.header-title{
    font-size: 16px;
    font-weight: bold;
    width: calc(38vw - 220px);
    text-align: center;
}

.arrow-right {
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-right: 30px solid transparent;
    border-left: 30px solid $yellow;
}

.MuiTabs-indicator{
    background-color: $yellow !important;
    height: 5px !important;
}

.Mui-selected{
    color: $black !important;
    font-weight: bold !important;
}
.themeSwitch{
    display:inline-block;
    margin:16px 0px;
    
}
